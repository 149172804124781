import { NullOrder, Order, type FacetQuery, type Field, type FilterByGeopoint, type FilterByQuery, type SortBy, type SortByCondition, type SortByGeopoint, type SortByNumber, type SortByTextMatch } from '~/types/typesense';

const wrap = (expr: string) => {
  return expr ? '(' + expr + ')' : expr;
};

const stringify = (data: unknown) => {
  switch (typeof data) {
    case 'string':
      return '`' + data.replace(/([`\\])/g, '\\$1') + '`';
    default:
      return `${data}`;
  }
};

const assert: (condition: unknown, msg?: string) => asserts condition
  = (condition, message = 'Assertion failed') => { if (!condition) throw new Error(message); };

const processFilterBy = (node: unknown, path: string[]) => {
  assert(node && typeof node === 'object');
  return Object.entries(node)
    .reduce<string[]>((terms, [key, value]: [string, unknown]) => {
      if (value === undefined) {
        return terms;
      }
      if (key.startsWith('$')) {
        switch (key) {
          case '$lt': {
            terms.push(`${path.join('.')}:<${stringify(value)}`);
            break;
          }
          case '$lte': {
            terms.push(`${path.join('.')}:<=${stringify(value)}`);
            break;
          }
          case '$eq': {
            terms.push(`${path.join('.')}:=${stringify(value)}`);
            break;
          }
          case '$ne': {
            terms.push(`${path.join('.')}:!=${stringify(value)}`);
            break;
          }
          case '$gte': {
            terms.push(`${path.join('.')}:>=${stringify(value)}`);
            break;
          }
          case '$gt': {
            terms.push(`${path.join('.')}:>${stringify(value)}`);
            break;
          }
          case '$in': {
            assert(Array.isArray(value));
            const operator = value.some(v => typeof v === 'string') ? ':=' : ':';
            terms.push(`${path.join('.')}${operator}[${value.map(stringify).join(',')}]`);
            break;
          }
          case '$polygon': {
            const points = value as FilterByGeopoint['$polygon'];
            assert(points);
            terms.push(`${path.join('.')}:(${points.join(',')})`);
            break;
          }
          case '$center': {
            const expr = value as FilterByGeopoint['$center'];
            assert(expr);
            const [point, radius] = expr;
            terms.push(`${path.join('.')}:(${point.join(',')},${radius}km)`);
            break;
          }
          case '$and': {
            assert(Array.isArray(value));
            const expr = value.map((sub: unknown) => wrap(processFilterBy(sub, path))).join('&&');
            if (expr) terms.push(wrap(expr));
            break;
          }
          case '$or': {
            assert(Array.isArray(value));
            const expr = value.map((sub: unknown) => wrap(processFilterBy(sub, path))).join('||');
            if (expr) terms.push(wrap(expr));
            break;
          }
          default: {
            throw new Error(`Unknown operator ${key}`);
          }
        }
      }
      else {
        const expr = processFilterBy(value, path.concat(key));

        if (expr) {
          terms.push(expr);
        }
      }
      return terms;
    }, [])
    .join('&&');
};

const processSortBy = <T>(node: unknown, path: string[]) => {
  assert(node && typeof node === 'object');
  const terms: string[] = [];
  loop: for (const [key, value] of Object.entries(node)) {
    switch (key) {
      case '$point': {
        const { $order, $point, $exclude_radius, $precision } = node as SortByGeopoint;
        let expr = path.join('.');
        expr += '(';
        expr += $point.join(',');
        if ($precision) expr += `,precision:${$precision}km`;
        if ($exclude_radius) expr += `,exclude_radius:${$exclude_radius}km`;
        expr += ')';
        if ($order === Order.Asc) expr += ':asc';
        if ($order === Order.Desc) expr += ':desc';
        terms.push(expr);
        break loop;
      }
      case '$expr': {
        const { $expr, $order } = node as SortByCondition<T>;
        let expr = `_eval(${buildFilterBy($expr)})`;
        if ($order === Order.Asc) expr += ':asc';
        if ($order === Order.Desc) expr += ':desc';
        terms.push(expr);
        break loop;
      }
      default: {
        switch (key) {
          case '$order':
          case '$null':
          case '$buckets': {
            const { $order, $null, $buckets } = node as SortByNumber & SortByTextMatch;
            let expr = path.join('.');
            if ($null === NullOrder.First) expr += `(missing_values:first)`;
            if ($null === NullOrder.Last) expr += `(missing_values:last)`;
            if ($buckets !== undefined) expr += `(buckets:${$buckets})`;
            if ($order === Order.Asc) expr += ':asc';
            if ($order === Order.Desc) expr += ':desc';
            terms.push(expr);
            break loop;
          }
          default: {
            const expr = processSortBy(value, path.concat(key));
            if (expr) terms.push(expr);
            break;
          }
        }
        break;
      }
    }
  }
  return terms.join(',');
};

/**
 * Builds a comma-separated list of fields from the document.
 */
const buildFieldsList = <T>(fields: Field<T>[]) => fields.join(',');

/**
 * Builds a string referencing `string | string[]` fields.
 *
 * https://typesense.org/docs/0.24.0/api/search.html#query-parameters
 */
const buildQueryBy = <T>(fields: Field<T>[]) => fields.join(',');

/**
 * Builds a query to filter conditions for refining your search results.
 *
 * https://typesense.org/docs/0.23.0/api/search.html#query-parameters
 */
const buildFilterBy = <T>(query: FilterByQuery<T>) => processFilterBy(query, []);

/**
 * Builds a string referencing fields that will be used for faceting your results on.
 *
 * https://typesense.org/docs/0.24.0/api/search.html#faceting-parameters
 */
const buildFacetBy = <T>(fields: Field<T>[]) => buildFieldsList<T>(fields);

/**
 * Builds a facet query to filters by facet values.
 * It supports only [a single field at the moment](https://github.com/typesense/typesense/issues/590).
 *
 * https://typesense.org/docs/0.24.0/api/search.html#faceting-parameters
 */
const buildFacetQuery = <T>(query: FacetQuery<T>) => buildFilterBy(query);

/**
 * Builds an expression to aggregate search results into groups or buckets by specify one or more group_by fields.
 *
 * NOTE: To group on a particular field, it must be a faceted field.
 *
 * https://typesense.org/docs/0.24.0/api/search.html#grouping-parameters
 */
const buildGroupBy = <T>(fields: Field<T>[]) => buildFieldsList<T>(fields);

/**
 * Builds a sort expression with a list of fields and their corresponding sort orders that will be used for ordering your results.
 *
 * https://typesense.org/docs/0.24.0/api/search.html#sort-results
 */
const buildSortBy = <T>(query: SortBy<T>) => processSortBy(query, []);

export const useTypesenseUtils = () => {
  return {
    buildFieldsList,
    buildQueryBy,
    buildFilterBy,
    buildFacetBy,
    buildFacetQuery,
    buildGroupBy,
    buildSortBy,
  };
};
