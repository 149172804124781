export interface SearchParamsWithPreset extends Partial<SearchParams> {
  preset: string;
}

export type OperationMode = 'off' | 'always' | 'fallback';

export interface SearchParams {
  // From https://typesense.org/docs/latest/api/documents.html#arguments
  'q': string;
  'query_by': string | string[];
  'query_by_weights'?: string | number[];
  'prefix'?: string | boolean | boolean[]; // default: true
  'filter_by'?: string;
  'sort_by'?: string | string[]; // default: text match desc
  'facet_by'?: string | string[];
  'max_facet_values'?: number;
  'facet_sample_threshold'?: number;
  'facet_sample_percent'?: number;
  'facet_query'?: string;
  'facet_query_num_typos'?: number;
  'facet_return_parent'?: string;
  'page'?: number; // default: 1
  'per_page'?: number; // default: 10, max 250
  'group_by'?: string | string[];
  'group_limit'?: number; // default:
  'include_fields'?: string | string[];
  'exclude_fields'?: string | string[];
  'highlight_fields'?: string | string[] | 'none'; // default: all queried fields
  'highlight_full_fields'?: string | string[] | 'none'; // default: all fields
  'highlight_affix_num_tokens'?: number; // default: 4
  'highlight_start_tag'?: string; // default: <mark>
  'highlight_end_tag'?: string; // default: </mark>
  'enable_highlight_v1'?: boolean;
  'snippet_threshold'?: number; // default: 30
  'num_typos'?: string | number | number[]; // default: 2
  'min_len_1typo'?: number;
  'min_len_2typo'?: number;
  'split_join_tokens'?: OperationMode;
  'exhaustive_search'?: boolean;
  'drop_tokens_threshold'?: number; // default: 10
  'typo_tokens_threshold'?: number; // default: 100
  'pinned_hits'?: string | string[];
  'hidden_hits'?: string | string[];
  'limit_hits'?: number; // default: no limit
  'pre_segmented_query'?: boolean;
  'enable_overrides'?: boolean;
  'enable_analytics'?: boolean;
  'prioritize_exact_match'?: boolean; // default: true
  'prioritize_token_position'?: boolean;
  'search_cutoff_ms'?: number;
  'use_cache'?: boolean;
  'max_candidates'?: number;
  'infix'?: string;
  'preset'?: string;
  'text_match_type'?: 'max_score' | 'max_weight';
  'vector_query'?: string;
  'x-typesense-api-key'?: string;
  'x-typesense-user-id'?: string;
  'offset'?: number;
  'limit'?: number;
  'stopwords'?: string;
}

export interface MultiSearchRequestSchema extends SearchParams {
  'collection'?: string;
  'x-typesense-api-key'?: string;
}

export type SearchResponseHighlightObject = {
  matched_tokens?: string[];
  snippet?: string;
  value?: string;
};

export type SearchResponseHighlight<T> = T extends string | number
  ? SearchResponseHighlightObject
  : {
      [TAttribute in keyof T]?: SearchResponseHighlight<T[TAttribute]>;
    };

export interface SearchResponseHit<T> {
  curated?: true;
  highlights?: [
    {
      field: keyof T;
      snippet?: string;
      value?: string;
      snippets?: string[];
      indices?: number[];
      matched_tokens: string[][] | string[];
    },
  ];
  highlight: SearchResponseHighlight<T>;
  document: T;
  text_match: number;
  text_match_info?: {
    best_field_score: string;
    best_field_weight: number;
    fields_matched: number;
    score: string;
    tokens_matched: number;
  };
}

export interface SearchResponseFacetCountSchema<T> {
  counts: {
    count: number;
    highlighted: string;
    value: string;
    parent?: unknown;
  }[];
  field_name: keyof T;
  stats: {
    avg?: number;
    max?: number;
    min?: number;
    sum?: number;
  };
}

export interface SearchResponseRequestParams extends Partial<SearchParams> {
  collection_name?: string;
}

export interface SearchResponse<T> {
  facet_counts?: SearchResponseFacetCountSchema<T>[];
  found: number;
  found_docs?: number;
  out_of: number;
  page: number;
  request_params: SearchResponseRequestParams;
  search_time_ms: number;
  hits?: SearchResponseHit<T>[];
  grouped_hits?: {
    group_key: string[];
    hits: SearchResponseHit<T>[];
    found?: number;
  }[];
}

export interface MultiSearchResponse<T extends unknown[] = []> {
  results: { [Index in keyof T]: SearchResponse<T[Index]> } & {
    length: T['length'];
  };
}

export interface SearchResponseHit<T> {
  curated?: true;
  highlights?: [
    {
      field: keyof T;
      snippet?: string;
      value?: string;
      snippets?: string[];
      indices?: number[];
      matched_tokens: string[][] | string[];
    },
  ];
  highlight: SearchResponseHighlight<T>;
  document: T;
  text_match: number;
  text_match_info?: {
    best_field_score: string;
    best_field_weight: number;
    fields_matched: number;
    score: string;
    tokens_matched: number;
  };
}

export type Geopoint = [lat: number, lng: number];

export type Vector = number[];

type _Field<Next, Path extends string | undefined = undefined> =
  Next extends boolean | number | string | Geopoint
    ? Path
    : Next extends (infer U)[]
      ? _Field<U, Path>
      : Next extends object
        ? {
            [K in keyof Next & string]: _Field<Next[K], Path extends string ? `${Path}.${K}` : K>;
          }[keyof Next & string]
        : never;

export type Field<Next> = _Field<Next>;

export type FilterByBoolean<T extends boolean> = {
  /**
   * Matches documents where the value of a field equals the specified value.
   */
  $eq?: T;
  /**
   * Selects the documents where the value of the field is not equal to the specified value. This includes documents that do not contain the field.
   */
  $ne?: T;
  /**
   * Selects the documents where the value of a field equals any value in the specified array
   */
  $in?: boolean[];
};

export type FilterByNumber<T extends number> = {
  /**
   * Selects the documents where the value of the field is less than (i.e. <) the specified value.
   */
  $lt?: T;
  /**
   * Selects the documents where the value of the field is less than or equal to (i.e. <=) the specified value.
   */
  $lte?: T;
  /**
   * Matches documents where the value of a field equals the specified value.
   */
  $eq?: T;
  /**
   * Selects the documents where the value of the field is greater than or equal to (i.e. >=) a specified value (e.g. value.)
   */
  $gte?: T;
  /**
   * Selects those documents where the value of the field is greater than (i.e. >) the specified value.
   */
  $gt?: T;
  /**
   * Selects the documents where the value of a field equals any value in the specified array
   */
  $in?: number[];
};

export type FilterByString<T extends string = string> = {
  /**
   * Matches documents where the value of a field equals the specified value.
   */
  $eq?: T;
  /**
   * Selects the documents where the value of the field is not equal to the specified value. This does not includes documents that do not contain the field.
   */
  $ne?: T;
  /**
   * Selects the documents where the value of a field equals any value in the specified array
   */
  $in?: string[];
};

export type FilterByGeopoint = {
  /**
   * The query returns coordinate pairs that are within the bounds of the circle.
   * Specify radius in kilometers.
   */
  $center?: [point: Geopoint, radius: number];
  /**
   * The query returns pairs that are within the bounds of the polygon.
   * The last point is always implicitly connected to the first.
   */
  $polygon?: Geopoint[];
};

type FilterByOperator<T> = T extends Geopoint
  ? FilterByGeopoint
  : T extends (infer U)[]
    ? FilterByOperator<U>
    : T extends object
      ? FilterBySubQuery<T>
      : T extends boolean
        ? FilterByBoolean<T>
        : T extends number
          ? FilterByNumber<T>
          : T extends string
            ? FilterByString<T>
            : never;

export type FilterExpression<T> = {
  $and?: FilterByQuery<T>[];
  $or?: FilterByQuery<T>[];
};

export type FilterBySubQuery<T> = {
  [K in keyof T]?: FilterByOperator<T[K]>;
};

export type FilterByQuery<T> = FilterBySubQuery<T> | FilterExpression<T>;

export type FacetQuery<T> = FilterByQuery<T>;

export enum NullOrder {
  First,
  Last,
}

export enum Order {
  Asc,
  Desc,
}

export interface SortByBoolean {
  /**
   * Sorts in the specified order
   */
  $order: Order;
  /**
   * Specify sort for null, empty or missing values
   */
  $null?: NullOrder;
}

export interface SortByNumber {
  /**
   * Sorts in the specified order
   */
  $order: Order;
  /**
   * Specify sort for null, empty or missing values
   */
  $null?: NullOrder;
}

export interface SortByString {
  /**
   * Sorts in the specified order
   */
  $order: Order;
  /**
   * Specify sort for null, empty or missing values
   */
  $null?: NullOrder;
}

export type SortByGeopoint = {
  /**
   * Sorts within a geo point
   */
  $point: Geopoint;
  /**
   * Sorts in the specified order
   */
  $order: Order;
  /**
   * Sorts nearby places within a radius based on another attribute
   */
  $exclude_radius?: number;
  /**
   * Buckets geo points into "groups" around the $point
   */
  $precision?: number;
};

export interface SortByCondition<T> {
  /**
   * Sorts based on conditions
   */
  $expr: FilterByQuery<T>;
  $order: Order;
}

export interface SortByTextMatch {
  /**
   * Sorts based on conditions
   */
  $order: Order;
  /**
   * Divides the result set into 10 buckets from most relevant results to the least relevant
   */
  $buckets?: number;
}

type SortByOperator<T> = T extends [lat: number, lng: number]
  ? SortByGeopoint
  : T extends (infer U)[]
    ? SortByOperator<U>
    : T extends object
      ? SortByField<T>
      : T extends boolean
        ? SortByBoolean
        : T extends number
          ? SortByNumber
          : T extends string
            ? SortByString
            : never;

type SortByField<T> = {
  [K in keyof T]?: SortByOperator<T[K]>;
};

export type SortBy<T> = { _text_match?: SortByTextMatch } |
  { _eval?: SortByCondition<T>[] } | SortByField<T>;
